.flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.affiliateTop {
  width: 100%;
  background-color: var(--blue);
  padding: 100px 0;
}

.affiliateTop .affiliate_top_text {
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
}

.affiliateTop .affiliate_top_text h1 {
  color: #fff;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.affiliateTop .affiliate_top_text h3 {
  color: #fff;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 0.9;
}

.affiliateTop .affiliate_top_text p {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 20px;
}

.affiliateTop .affiliate_top_image {
  width: 90%;
  height: 100%;
  border-radius: 62% 38% 46% 54% / 57% 54% 46% 43%;
  margin-bottom: 20px;
  min-height: 500px;
}



/* AffiliateHighlights */
.affiliateHighlights {
  padding: 80px 0;
  background: var(--lightsky);
}

.affiliate_highlights_text {
  padding: 30px;
}

.affiliate_highlights_image {
  height: 65%;
  width: 100%;
  border-radius: 59% 41% 58% 42% / 52% 45% 55% 48%;
  background-repeat: no-repeat !important;
  background-size: cover !important;

}

.affiliate_highlights_text_head {
  font-size: 2rem;
  font-weight: 700;
  color: var(--blue);
  margin-bottom: 20px;
}

.affiliate_highlights_text ul {
  padding: 0;
  list-style: none;
}

.affiliate_highlights_text ul li {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.affiliate_highlights_text ul li .icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 0 0;
}

.affiliate_highlights_text ul li .icon svg {
  font-size: 2.3rem;
  color: green;
}

.list_text {
  width: 95%;
}

.affiliate_highlights_text ul li .list_text_head {
  font-weight: 700;
  margin: 15px 0;
  font-size: 1.6rem;
  color: var(--blue);
}

.affiliate_highlights_text ul li .list_text_des {
  font-size: 1.5rem;
  color: var(--blue);
  font-weight: 500;
}

/* affiliate_should_join */
.affiliate_should_join {
  width: 100%;
  padding: 100px 0;
}

.affiliate_should_text {
  padding: 20px 10px;
}

.affiliate_should_text p {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--blue);
}

.affiliate_should_text ul li {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--blue);
  margin-bottom: 20px;
}

.radius_image_design {
  width: 80%;
  min-height: 450px;
  border-radius: 58% 52% 53% 47% / 49% 51% 59% 51%;

}

.requirements {
  background-size: cover !important;
  background-position: center !important;
}

.shouldJoin {
  background-size: cover !important;
  background-position: center !important;
}

.skybg {
  background-color: var(--lightsky);
}

/* affiliateForm */
.affiliateForm {
  padding: 100px 0;
}

.affiliateFormHead {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--blue);
}

.affiliateFormDes {
  font-weight: 500;
  font-size: 1.4rem;
  width: 90%;
}


/* affiliateFAQ */
.affiliateFAQ {
  padding: 50px;
}

.affiliateFAQ h1 {
  text-align: center;
  font-weight: 700;
  color: var(--blue);
  font-size: 3.5rem;

}

.affiliateFAQ .accordion {
  border: none !important;
  margin-top: 30px;
}

.affiliateFAQ .accordion-item .accordion-button .icon_faq {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 5px;
  color: var(--blue);
}

.affiliateFAQ .accordion .accordion-item {
  border: none !important;
  box-shadow: 0px 10px 30px #0000020F;
  margin-bottom: 10px;
}

.affiliateFAQ .accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  font-weight: 500;
}

.affiliateFAQ .accordion .accordion-item .accordion-button:focus {
  box-shadow: none !important;
}

.affiliateFAQ .accordion .accordion-item .accordion-button {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  font-weight: 500;
}

.affiliateFAQ .accordion .accordion-item .accordion-body {
  font-size: 0.9rem;
  font-weight: 400;
  color: #7A7A7A !important;
  padding-left: 50px;
}