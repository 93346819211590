.top_button_section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.top_button_section ul {
  width: 70%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
}

.top_button_section ul li:first-child {
  border-radius: 5px 0 0 5px;
}

.top_button_section ul li:last-child {
  border-radius: 0 5px 5px 0;
  border-left: 0;
}

.top_button_section ul li {
  list-style: none;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 60px;
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--blue);
  border: thin solid var(--blue);
  cursor: pointer;
}

.top_button_section ul li.active {
  background-color: var(--blue);
  color: #fff !important;
}

.pricing_item {
  border-radius: 15px !important;
  height: 100% !important;
  border: thin solid var(--blue);
  padding: 20px;
  position: relative;
}

.pricing_item.most {
  border: 3px solid var(--yellow);
}

.pricing_item.most .select_plan {
  background-color: var(--yellow) !important;
}

.most_overlay {
  position: absolute;
  top: -3%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  display: flex;
  justify-content: space-around;
  background-color: var(--yellow);
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.most_overlay h4,
.most_overlay svg {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
}

.pricing_item .pricing_item_header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.pricing_item .pricing_item_header h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0 !important;
  color: var(--blue);
}

/* .pricing_item_price {
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.pricing_item .pricing_item_price {
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;


}

.pricing_item .pricing_item_price .pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

}

.pricing_item .pricing_item_price .pricing sup {
  font-size: 1.2rem;
  margin-right: 5px;
  margin-top: 10px !important;
  font-weight: 600;
  color: var(--blue);
}

.pricing_item .pricing_item_price .pricing .dollar {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--blue);
  margin-top: -25px;
}

.pricing_item .pricing_item_price .pricing .amount {
  font-size: 2rem;
  font-weight: 600;
  color: var(--blue);
  line-height: 1;

}

.pricing_item .pricing_item_price .pricing .type {
  font-size: 1rem;
  margin-top: 15px;
  font-weight: 700;
  color: var(--blue);
}

.select_plan {
  color: #ffff;
  font-weight: 700;
  font-size: 0.8rem !important;
  width: 80%;
  text-transform: uppercase;
  margin: 10px 0;
  outline: none;
  border: none;
  padding: 10px 20px;
  border-radius: 100px;
  transition: all 0.5s ease-out;
  background-color: var(--blue);
}

.select_plan:hover {
  background-color: var(--yellow);
}

.pricing_item .pricing_item_price .details {
  margin-top: 10px;
  width: 100%;
}

.pricing_item .pricing_item_price .details h4 {
  color: var(--blue);
  font-weight: 700;
  font-size: 1rem;
}

.pricing_item .pricing_item_price .details ul {
  padding-left: 15px !important;
}

.pricing_item .pricing_item_price .details ul li {
  padding: 2px 0 !important;
  font-size: 0.9rem;
  color: var(--blue);
  font-weight: 400;
}