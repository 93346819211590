.review {
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 50px 0 20px 0;
}

.review h4 {
  font-size: var(--heading);
  font-weight: 700;
  color: var(--blue);
}

.slider_div {
  overflow: hidden;
  padding: 20px 0;
}

.slider_div .slide_item {
  margin: 5px;
}

.slide_item .card {
  border: none;
  padding: 0 !important;
}

.slide_item .card .card-body .top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.slide_item .card .card-body .top .review_img {
  width: 50px;
  margin: 5px;
  border-radius: 100px;
  overflow: hidden;
}

.slide_item .card .card-body .top .review_img img {
  width: 100%;
  height: auto;
  max-height: 50px;
}

.slide_item .card .card-body .top h5 {
  font-weight: bold;
  margin: 0 15px 0 10px;
}

.slide_item .card .card-body .top h6 {
  margin: 0;
  color: var(--text);
}

.slide_item .card .card-body p {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  min-height: 70px;
}

.slide_item .card .card-body .review_stars {
  display: flex;
  list-style: none;
}

.slide_item .card .card-body .review_stars li svg {
  font-size: 1.3rem;
  color: var(--gold);
}