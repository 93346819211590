@import url('./fonts.css');
@import url('./variables.css');
@import url('./about.css');
@import url('./animation.css');
@import url('./main.css');
@import url('./responsive.css');
@import url('./pricing.css');
@import url('./landscap.css');
@import url('./review.css');
@import url('./affiliate.css');


* {
  font-family: 'Poppins', sans-serif !important;
}

.require_span {
  font-size: var(--des) !important;
}

.wrap {

  width: 100%;
  overflow: hidden;
  height: auto;
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

strong {
  margin-right: 5px;
  color: var(--blue);
}

.glassModel {
  background: rgba(255, 255, 255, 0.17) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.7px);
  border-radius: 10px !important;
  -webkit-backdrop-filter: blur(5.7px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
}

.glassModel .modal-content {
  background: var(--lightsky) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
}

.glassModel .modal-content svg {
  cursor: pointer;
  color: var(--blue);
}

.modal-content svg:hover {
  color: var(--gold);
}

.glassModel #page-content-wrapper {
  min-height: auto;
}

/* Popup modal */
.popupModal {
  background: rgba(255, 255, 255, 0.17) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.7px);
  border-radius: 0 !important;
  -webkit-backdrop-filter: blur(5.7px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
}

.popupModal .modal-content {
  background: var(--lightsky) !important;
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid rgba(255, 255, 255, 0.04) !important;
}

/* .popupModal .modal-content .text-body {} */

.popupModal .modal-content .text-body h3 {
  color: var(--blue);
  font-weight: bold;
  margin: 15px 0;
  text-align: center;

}

.popupModal .modal-content .text-body h2 {
  font-weight: 700;
  color: var(--gold);
  font-size: 2.2rem;
  text-align: center;
}

.popupModal .modal-content .text-body p {
  text-align: center;
  font-weight: 500;
}

.popupModal .modal-content .text-body p a {
  color: var(--gold);
  font-weight: 600;
}

.popupModal .modal-content .text-body .btn {
  width: 100% !important;
  background-color: var(--blue) !important;
  border: 0 !important;
  font-weight: 600;
}

.popupModal .modal-content .text-body label {
  color: #00274e;
  color: var(--blue);
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: none;
}

.popupModal .modal-content .text-body .form-control:is(:focus, :hover) {
  background-color: #cae8f39b !important;
  border: 1px solid #aaa;
}

.popupModal .modal-content .text-body .form-control {
  background-color: #cae8f39b;
  color: #00274e;
  color: var(--blue);
  font-size: .9rem;
  padding: 10px 20px;
}

.btn-close {
  color: var(--blue) !important;
}


.newsletter_message_modal {
  text-align: center;
}

.newsletter_message_modal h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gold);
}

.newsletter_message_modal h1 {
  font-size: 1.8rem;
  line-height: 1.4;
  color: var(--blue);
}

/* for dashbaod modal */
.newsletter_message_modal h2 {
  font-weight: 700;
  color: var(--blue);
  letter-spacing: 1;
  margin-top: 30px;
}

.loaderBlocker {
  background-color: rgba(0, 0, 0, 0.552);
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;

}

.loader {
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid var(--darkblue);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.loader-small {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--darkblue);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Terms and Condition */
.terms_and_condition {
  margin-top: 20px;
}

.terms_and_condition .term_box {
  margin: 20px 0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
}

.terms_and_condition2 {
  background-color: #000;
  padding: 40px 20px;
}


.terms_and_condition .term_box ul {
  padding-left: 20px !important;
}

.terms_and_condition .term_box h4 {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--blue);
  /* margin: 15px 0 5px 0; */
  /* padding: 0 10px !important; */
}

.terms_and_condition .term_box p {
  padding-left: 10px;
  font-weight: 400;
  margin-bottom: 5px;
}

.terms_and_condition .term_box p span {
  color: var(--blue);
  font-weight: 500;
  margin-right: 5px;
}

.video-react.video-react-fluid {
  height: 100% !important;
}

.video-react .video-react-video {
  width: auto !important;
}

.pagination_navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
}

.pagination_navigation li {
  margin: 0 10px;
}

/*  */
.pagination_navigation li:not(:first-child):not(:last-child) {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: var(--blue);
  color: #fff;
}

.pagination_navigation li {
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s linear;
}

.pagination_navigation li.active {
  background-color: var(--gold) !important;
}

.pagination_navigation li.previous.disabled {
  color: #cbc0c0 !important;
}

.pagination_navigation li.next.disabled {
  color: #cbc0c0 !important;
}

.not_found_record {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.not_found_record img {
  width: 200px;
  height: 200px;
}


/* Under Mantanence page */
.underMantanence {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.underMantanence .text_section {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
}

.underMantanence .overlay {
  width: 900px;
  height: 900px;
  min-height: 1000px;
  background-color: rgba(235, 232, 232, 0.2);
  padding: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.underMantanence .overlay .overlay1 {
  background-color: rgba(235, 232, 232, 0.5);
  border-radius: 100%;
  width: 95%;
  height: 95%;
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.underMantanence .overlay .overlay1 .overlay2 {
  background-color: rgba(235, 232, 232, 0.9);
  border-radius: 100%;
  width: 100%;
  height: 100%;
}


.underMantanence .text_section h3 {
  font-size: 3rem;
  margin: 30px 0;
  color: var(--blue);
  font-weight: 600;
}

.underMantanence .text_section p {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--blue);
}

.success {
  color: #0a3622 !important;
}

.cst-success {
  margin-top: 20px;
  border: 0 !important;
  font-weight: 500;
}

.danger {
  color: #58151c !important;
}

.success:hover,
.danger:hover {
  color: var(--gold) !important;
}

#hubspot-messages-iframe-container {
  margin-bottom: 70px !important;
}

.videoModal .modal-content,
.videoModal .modal-header {
  background-color: transparent !important;
  border: 0;
}

.modal-header .btn-close {
  color: #fff !important;
  --bs-btn-close-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e') !important;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* preClosure page */
.powerTitle {
  font-size: 1.25rem;
  color: #475467;
  line-height: 1.7;
}

li.dropdown {
  position: relative;
  cursor: pointer;
}

.navStep2 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 250px;
  background-color: #f8f9fa;
  list-style: none;
  padding: 10px 8px;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s linear;
}

li.dropdown:hover .navStep2,
li.dropdown:focus-visible .navStep2 {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateY(0);
}

.navStep2 li {
  padding: 5px 10px;
  transition: all 0.2s ease;
}

.navStep2 li a {
  font-size: 0.9rem;
  color: var(--blue);
  text-decoration: none;
  display: block;
  padding: 8px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.navStep2 li a:hover,
.navStep2 li a:focus {
  background-color: var(--blue);
  color: white;
}

li.dropdown svg {
  transition: all 0.3s ease;
}

li.dropdown:hover svg {
  transform: rotate(180deg);
}

@media (max-width: 992px) {
  li.dropdown {
    position: relative;
  }

  .navStep2 {
    width: 100%;
    margin-left: 0;
  }

  li.dropdown:hover .navStep2 {
    position: relative;
    left: 0;
    top: 100%;
  }
}