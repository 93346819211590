:root {
  --y: #e4ab3e;
}
@keyframes switchToProActive {
  0% {
    z-index: 0;
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    z-index: 1;
  }
}

/* COnvert To Pro */

@keyframes switchToProBorder {
  from {
    border: 4px solid var(--y);
  }
  to {
    border: 4px solid var(--blue);
  }
}

@keyframes switchToProBG {
  from {
    background-color: var(--y);
  }
  to {
    background-color: var(--blue);
  }
}

/* Convert to Basic */
@keyframes switchToBaiscBorder {
  from {
    border: 4px solid var(--blue);
  }
  to {
    border: 4px solid var(--y);
  }
}

@keyframes switchToBaiscBG {
  from {
    background-color: var(--blue);
  }
  to {
    background-color: var(--y);
  }
}

/* Package duration */
@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Package duration */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
