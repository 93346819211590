:root {
    --darkblue: #0b2548 !important;
    --lighblue: #043a80 !important;
    --yellow: #b4975a;
    --lightsky: #EFFAFE;
    --blue: #011C40;
    --gold: #C9922B;
    --text: #a3a3a3;
    --hero: 3rem;
    --heading: 2.3rem;
    --subhead: 1.4rem;
    --title: 1.2rem;
    --des: 1rem;
}