/* Iphone XR */
@media (width:896px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* Iphone 12 pro */
@media (width:844px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* Iphone 14 pro  max*/
@media (width:932px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* pixil 7 */
@media (width:915px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* IPad Mini */
@media (width:768px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* IPad Mini Air */
@media (width:820px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}

/* IPad Mini Air */
@media (width:912px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}


/* Galaxy A51 */
@media (width:914px) {
  .desktop {
    flex-direction: row !important;
  }

  .desktop li {
    margin: 0 !important;
  }

  .desktop .desktop li a {
    width: 100%;
  }
}