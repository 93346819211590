@charset "UTF-8";

body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #383838;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  overflow-x: hidden;
  background-color: #fff;
}

:root {
  --yellow: #c9922b;
  --blue: #00274e;
  --yellow-shadow: #d38f0040;
  --blue-shadow: #00285157;
  --light-yellow: #d4af62;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background-color: #fff !important;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

ul,
li {
  margin: 0;
  padding: 0 !important;
}

a {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 16px;
  /* color: #000; */
  text-decoration: none !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

img {
  border: 0 none;
}

p {
  margin: 0 0 15px 0;
}

.btn-primary {
  color: #e0e8f0;
  text-align: center;
  background: #00274e !important;
  padding: 10px 30px !important;
  display: block;
  border-radius: 25px !important;
  font-size: var(--des);
  font-weight: 600 !important;
  box-shadow: 0px 10px 30px #00285183;
  transition: all 0.5s ease-out;
  border: none !important;
  text-decoration: none;
}

.btn-primary.main {
  color: #e0e8f0;
  text-align: center;
  background-color: #00274e;
  padding: 10px 30px !important;
  display: block;
  border-radius: 25px !important;
  font-size: var(--des);
  font-weight: 600;
  min-width: 100%;
  box-shadow: 0px 10px 30px #00285183;
  margin: 0 auto;
  transition: all 0.5s ease-out;
  border: none !important;
  text-decoration: none;
}

.btn-primary.main:hover,
.btn-primary:hover,
.btn-primary.main:active {
  background-color: #c9922b !important;
  color: #fff;
  box-shadow: 0px 10px 30px #c9922b;
}

a.main {
  color: #fff;
  background-color: var(--blue);
  text-align: center;
  padding: 10px 25px !important;
  display: block;
  border-radius: 25px !important;
  font-size: var(--des);
  font-weight: 500;
  margin: 0 auto;
  text-decoration: none;
  transition: all 0.5s linear;
  width: 150px;
}

a.main:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 10px 30px #c9922b;
}

.headersection {
  max-width: 100%;
  width: 100%;
  padding: 15px 0;
  background: #fff;
}

.headersection .container {
  max-width: 1600px;
  width: 100%;
  padding: 0 15px;
}

.headersection .logo {
  width: 100%;
  max-width: 230px;
  margin-top: 0px;
}

.headersection .logo img {
  width: 100%;
}

.header-tabs ul {
  display: flex;
  list-style: none;
  justify-content: flex-end;
}

.header-tabs .firstTab {
  background-color: #00274e;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  border-radius: 30px;
  text-align: center;
  padding: 5px 25px;
}

.header-tabs {
  margin-left: auto;
}

.header-tabs .firstTab {
  background-color: #00274e;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  border-radius: 30px;
  text-align: center;
  padding: 7px 30px;
  margin-right: 15px;
  box-shadow: 0px 10px 30px #00285157;
}

.header-tabs .lastTab {
  background-color: #c9922b;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  border-radius: 30px;
  text-align: center;
  padding: 7px 30px;
  box-shadow: 0px 10px 30px #d38f0040;
}

.header-tabs a {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}

.header-tabs li:hover {
  color: #00274e;
  background-color: #c3cddc;
  transition: all 0.5s ease-out;
  box-shadow: 0px 10px 30px #d38f0040;
}

.header-tabs li:hover a {
  color: #00274e;
}

.header-tabs {
  padding-top: 12px;
  position: relative;
}

.header-tabs:before {
  position: absolute;
  left: 30px;
  content: "";
  width: 2px;
  background: #e6e7e9;
  top: 16px;
  bottom: 5px;
}

.navigation .nav-item {
  padding: 0 20px;
}

.navigation .nav-item {
  position: relative;
}

.navigation .nav-item a {
  color: #161c2d;
  font-size: 18px;
  font-weight: 400;
}

.main-form h2 {
  letter-spacing: 0px;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0;
}



.navigation .nav-item.active a {
  color: #ba964f;
}

.navigation .nav-item.active:before {
  height: 4px;
  left: 28px;
  right: 28px;
  background: #ba964f;
  position: absolute;
  margin: auto;
  content: "";
  top: -5px;
}

.navigation .nav-item a:hover {
  color: #ba964f;
  text-decoration: none;
}

.main-form::placeholder,
.main-form2::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #202020;
}


.main-form2 label {
  color: var(--blue);
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 0px;
}

.main-form label {
  color: #fff;
  font-size: 14px;
  display: block;
  font-weight: 600;
  letter-spacing: 1px;
}

.main-form p {
  color: #fff;
  font-size: 0.9rem;
}

@media (max-width: 992px) {
  .main-form {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .main-form {
    height: 100% !important;
  }
}

.main-form {
  width: 100%;
  padding: 40px;
  min-height: 500px !important;
  height: auto;
  border-radius: 10px;
  background: rgba(0, 40, 81, 1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(0, 40, 81, 0.09);
}

.main-form2 {
  width: 100%;
  padding: 20px 20px 0 20px;
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.signup_image_div {
  height: 100vh;
  min-height: 810px;
  position: relative;
}

.signup_image_div .text-div {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;

}

.signup_image_div .text-div ul {
  margin-left: 15px;
}

.signup_image_div ul li,
.signup_image_div p,
.signup_image_div h5 {
  text-align: left !important;
  color: #00284F;
  font-weight: 600;
}

.signup_image_div h4 {
  text-align: left;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--blue);
}

@media (max-width: 380px) {
  .main-form {
    min-height: 400px !important;
    height: auto !important;
  }
}

.main-form .tab-container {
  padding-top: 15px;
}

.main-form legend,
.main-form2 legend {
  font-size: 1.5rem;
  min-height: 95px;
  position: relative;
  margin: 0 0 10px 0;
}

.main-form legend .fa,
.main-form2 legend .fa {
  position: absolute;
  right: 12px;
  top: 50px;
  font-size: 22px;
  cursor: pointer;
}

.main-form2 .submit_link {
  background: var(--blue);
  width: 100% !important;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: 0px 4px 8px #d1e8ef93;
}

.main-form .submit,
.main-form2 .submit {
  background: var(--blue);
  width: 100%;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: 0px 4px 8px #d1e8ef93;
}

.main-form .submit:hover,
.main-form2 .submit:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 15px #d1e8ef93;
}

.main-form .reset-tab,
.main-form2 .reset-tab {
  background: #fff2d6;
  text-align: center;
  color: #002854;
  text-align: center;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1rem;
  text-decoration: none;
}

.registerbtn {
  background: var(--gold) !important;
  width: 100%;
  text-align: center;
  color: #fff !important;
  border: none;
  outline: 0;
  text-align: center;
  font-weight: 700 !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-size: 1rem !important;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0px 3px 6px #fff1d383;
}

.registerbtn:hover {
  transform: scale(1.02);
  box-shadow: 0px 3px 15px #fff1d383;
}

.main-form::placeholder,
.main-form2::placeholder {
  color: #202020;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.main-form .forgot-password span,
.main-form2 .forgot-password span {
  padding: 14px 0;
  color: var(--blue);
  font-weight: 600;
}

.main-form .forgot-password span a,
.main-form2 .forgot-password span a {
  margin-left: 5px;
  letter-spacing: 0px;
  text-decoration: none;
  color: var(--blue) !important;
  font-size: 15px;
}

.forgot-password-login {
  color: var(--blue);
  font-weight: 500;
}

.main-form .forgot-password a.second-link,
.main-form2 .forgot-password a.second-link {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.main-form input[type="email"]::placeholder,
.main-form2 input[type="email"]::placeholder {
  color: #fff;
  font-weight: 400;
  font-size: 0.9rem;
}

.header-inner {
  background-color: var(--lightsky);
  color: #161c2d;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-inner2 {
  color: #161c2d;
  width: 100%;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-inner2 h3,
.header-inner2 h1 {
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-size: var(--heading);
  margin: 0;
}

.header-inner2 h6 {
  color: var(--light-yellow);
  font-weight: 600;
  font-size: 56px;
  margin-bottom: 20px;
}

.header-inner2 p {
  color: #fff;
  font-weight: 300;
  width: 100%;
  font-size: var(--des);
  margin: auto;
  max-width: 530px;
}

.header-inner h1 {
  color: #161c2d;
  text-align: center;
  font-size: var(--heading);
  font-weight: 700;
}

.header-inner p {
  font-size: var(--title);
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.pricing-plan {
  width: 100%;
  max-width: 100%;
  padding: 100px 0 40px;
}

.pricing-plan h6 {
  text-align: center;
  border-radius: 16px;
  background: #e5e9ed;
  color: #00274e;
  display: block;
  padding: 5px 10px;
  margin: 0 auto 45px;
  width: 150px;
}

.pricing-plan h5 {
  text-align: center;
  color: #00274e;
  font-size: 40px;
  margin-bottom: 30px;
}

.plan-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.priceCardDesign {
  border-radius: 12px;
  padding: 10px;
  box-shadow: rgba(0, 0, 255, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transition: all 0.2s linear;
}

.priceCardDesign:hover {
  box-shadow: rgba(0, 40, 81, 0.3) 0px 0px 10px 0px;
}

.priceCardDesign:hover .card_title {
  box-shadow: 0px 7px 25px #00295480;
  background: transparent linear-gradient(128deg, #00274e 0%, #366c98 100%) 0% 0% no-repeat padding-box;
}

.priceCardDesign:hover a {
  box-shadow: 0px 7px 25px #00274e;
  background: transparent linear-gradient(128deg, #00274e 0%, #366c98 100%) 0% 0% no-repeat padding-box;
}

.priceCardDesign .card_title {
  background: transparent linear-gradient(128deg, #ba964f 0%, #dbad53 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 25px #c1944184;
  border-radius: 19px;
  font-size: 0.9rem;
  font-weight: 500;
  margin: 10px 0;
  padding: 5px 15px;
  color: #fff;
  text-align: center;
  transition: all 0.2s linear;
}

.priceCardDesign .card_price {
  font-size: 3rem;
  color: #1a1b1e;
  position: relative;
}

/* .priceCardDesign img {
  width: 200px;
  margin: 15px auto;
} */

.priceCardDesign .price p {
  font-size: 1.8rem;
  color: #1a1b1e;
  margin: 15px 0;
}

.priceCardDesign .price p small {
  font-size: 0.8rem;
}

.priceCardDesign .des {
  font-size: 0.9rem;
  text-align: center;
  line-height: 20px;
  display: block;
  width: 100%;
}

.priceCardDesign button {
  box-shadow: 0px 2px 15px #ba964f;
  background: transparent linear-gradient(128deg, #ba964f 0%, #dbad53 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 8px 30px;
  margin: 20px auto;
  border-radius: 20px;
  transition: all 0.2s linear;
}

.pricing_head {
  font-size: 1.2rem;
  text-align: center;
  width: 60%;
  margin: 10px auto;
}

.pricing-plan .plan-box {
  border-radius: 12px;
  min-height: 545px;
  background: url(../../assets/images/icon-box.svg) no-repeat 35% 38px;
  padding: 30px;
  margin-bottom: 50px;
  position: relative;
  padding-top: 319px;
  padding-left: 16%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.pricing-plan .plan-box h4 {
  position: absolute;
  background: transparent linear-gradient(128deg, #ba964f 0%, #dbad53 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 25px #c1944184;
  border-radius: 19px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  display: block;
  width: 130px;
  height: 37px;
  left: 23px;
  top: 53px;
  padding-top: 7px;
}

.pricing-plan .plan-box .price big {
  font-size: 3rem;
  color: #1a1b1e;
  position: relative;
}

.pricing-plan .plan-box .price big:before {
  content: "$";
  font-size: 24px;
  color: #e4ab3e;
  position: absolute;
  left: -20px;
  top: 22px;
}

.pricing-plan .plan-box .price span {
  display: block;
  padding-top: 35px;
  text-align: center;
  margin-bottom: 35px;
}

.pricing-plan .plan-box .price span span {
  padding-top: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.pricing-plan .plan-box .tab {
  background: #dbad53 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #e4ab3e76;
  border-radius: 25px;
  opacity: 1;
  width: 168px;
  height: 50px;
  display: block;
  text-align: center;
  padding-top: 14px;
  color: #fff;
  text-decoration: none;
  margin: 0 auto;
}

.pricing-plan .last-box {
  padding-left: 30px !important;
  padding-right: 10px;
}

.pricing-plan .plan-box:hover {
  border: 3px solid #00274e;
  transition: all 0.5s ease-out;
}

.pricing-plan .plan-box:hover big:before {
  color: #00274e;
}

.pricing-plan .plan-box:hover h4 {
  background: transparent linear-gradient(128deg, #00274e 0%, #366c98 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 25px #00285184;
  opacity: 1;
}

.pricing-plan .plan-box:hover .tab {
  background: #002851 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 25px #00295480;
  border: 1px solid #00274e;
  opacity: 1;
}

/* .main-form input {
  border-radius: 10px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  font-size: 1rem;
  font-weight: 500;
  border: 0;
} */
.contact-page {
  max-width: 100%;
  width: 100%;
  padding: 50px 0;
}

.contact-page .top_label {
  text-align: center;
  border-radius: 16px;
  background: #e5e9ed;
  color: #00274e;
  font-size: 0.9rem;
  padding: 5px 15px;
}

.contact-page input {
  border: 0;
  width: 100%;
  background: var(--lightsky) !important;
  color: #161c2d;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 0;
}

.contact-page input:is(:hover, :focus, :focus-visible) {
  /* border: 1px solid var(--blue) !important; */
  outline: none !important;
  box-shadow: 0 0 5px 0 #eee !important;
}

.contact-page input::placeholder,
.contact-page textarea::placeholder {
  color: var(--blue);
  font-size: 0.9rem;
}

.contact-page legend {
  position: relative;
  margin-bottom: 30px;
}

.contact-page textarea {
  border: 0;
  background: var(--lightsky) !important;
  padding-top: 15px;
  width: 100%;
  font-size: 0.9rem;
  border-radius: 0;
  color: var(--blue);
}

.contact-page textarea:is(:hover, :focus, :focus-visible) {
  box-shadow: none !important;
  /* border: 1px solid var(--blue); */
}

.contact-page .contact_text h3 {
  font-weight: 600;
  color: var(--blue);
}

.contact-page .contact_text p {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}

.contact_text ul {
  margin-left: 15px;
}

.contact-page .contact_text ul li {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 10px;
}

.contact-page .submit {
  background: #00274e 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #00274e6c;
  border-radius: 20px;
  padding: 10px 30px;
  text-align: center;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: var(--des);
  transition: 0.3s linear;
}

.contact-page .submit:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 10px 30px #c9922b;
}

.contact-page::placeholder {
  color: #161c2d;
  font-size: 20px;
  opacity: 1;
}

.contact-page h3 {
  color: #161c2d;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px 0;
}

.contact-page .left-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #E7E7E7;
  padding: 20px 10px;
  height: 120px;
  margin: 15px 0;
  border-radius: 10px;

}

.contact-page .left-contact .icon {
  width: 60px;
  height: 60px;
  background-color: var(--blue);
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s linear;
}

.contact-page .left-contact .icon:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 10px 30px #c9922b;

}

.contact-page .left-contact .icon svg,
.contact-page .left-contact .icon2 svg {
  font-size: 2.5rem;
  color: #fff;
}

.contact-page .contact-box {
  width: 70%;
  padding: 0 10px;
}

.contact-page .contact-box a {
  color: var(--blue);
  font-size: var(--des);
  text-decoration: none;
}

.contact-page .contact-box h4 {
  font-size: var(--title);
  color: #161c2d;
  margin-bottom: 0;
  font-weight: 600;
}

.contact-page .contact-box p {
  color: var(--blue);
}

.left-contact2 h3 {
  font-size: var(--heading);
  margin-bottom: 0;
  font-weight: 700;
  color: var(--blue);
}

.left-contact2 p {
  font-size: var(--subhead);
  color: var(--blue);
  line-height: 1.4;
  font-weight: 600;
}

/* 


.contact-box .icon2 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: transparent linear-gradient(128deg, #002851 0%, #1c6e9c 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 60px #00274e5f;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  top: -42px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.main-form .error-messege {
  font-size: 0.9rem;
  line-height: normal;
  color: #b4975a;
  padding-top: 4px;
  font-weight: 300;
}

.login-signup {
  max-width: 100%;
  width: 100%;
  padding: 100px 0;
}

.newsletter-cont {
  max-width: 100%;
  width: 100%;
  background: url(../../assets/images/left-bg.png) no-repeat left top,
    url(../../assets/images/right-bg.png) no-repeat right bottom, #f4f7fa;
  padding: 120px 0;
  height: auto;
}

.newsletter-cont h6 {
  background-color: rgba(0, 38, 75, 0.15);
  padding: 5px 15px;
  color: #00264b;
  display: block;
  text-align: center;
  max-width: 160px;
  width: 100%;
  border-radius: 26px;
  margin: 0 auto 30px;
}

.newsletter-cont .container {
  text-align: center;
  max-width: 700px;
}

.newsletter-cont h3 {
  font-size: 42px;
  color: #161c2d;
  text-align: center;
  margin: 0 auto 30px;
}

.newsletter-cont input {
  width: 100%;
  height: 95px;
  border-radius: 90px;
  border: 0;
  background-color: #fff;
  font-size: 17px;
  color: #777777;
  padding-left: 25px;
}

.newsletter-cont input:focus-visible {
  border: none;
  outline: none;
}

.newsletter-cont legend {
  position: relative;
}

.newsletter-cont .submit {
  background-color: #002b58;
  color: #fff;
  text-align: center;
  width: 210px;
  top: 16px;
  right: 16px;
  position: absolute;
  height: 65px;
  font-size: 15px;
  border-radius: 40px;
  font-weight: 600;
}

.form-newsletter {
  margin-bottom: 25px;
}

.form-newsletter {
  display: block;
  min-height: 124px;
}

.footer {
  background-color: #002952;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 20px 0;
}

.footer .footer_div {
  width: 85%;

}

.footer .footer-content {
  width: 90%;
}

.footer .footer-content p {
  color: #fff;
  font-size: 0.9rem;
  line-height: 2rem;
}

.footer .footer-content h4 {
  font-size: 1.2rem;
}

.footer a {
  font-weight: 300;
  text-decoration: none;
}

.footer .footerColumn.link a {
  color: #fff !important;
  cursor: pointer;
}

.footer .footerColumn.link a.active {
  color: var(--gold) !important;
}

.footer .footerColumn a:hover {
  color: #ba964f !important;
  font-weight: 300;
  text-decoration: none;
}

.footer .footerColumn li {
  margin-bottom: 10px;
}

.footerColumn h4 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 20px 0;
  font-weight: 600;
}

.socialLinks {
  margin-bottom: 20px;
}

.socialLinks h4 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 25px 0;
  font-weight: 400;
}

.newsletter h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 550;
  margin: 0 0 25px 0;
}

.newsletter p {
  color: #fff;
  font-weight: 500;
}

.newsletter .input_group {
  width: 100%;
  position: relative;
}

.newsletter .input_group button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--blue);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1.6rem !important;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  transition: all 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:576px) {
  .newsletter .input_group button {
    width: auto !important;
  }

}

.newsletter .input_group button:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 10px 30px #00285183;
}

.newsletter .input_group input {
  box-shadow: 0 10px 30px #bcb7ff5c;
  padding: 10px 15px;
  width: 100%;
  border-radius: 40px;
  border: none;
  outline: none;
  height: 100%;
}

.newsletter .input_group input::placeholder {
  color: var(--blue);
  font-size: 0.9rem;
  font-weight: 500;
}

.footer .footerColumn ul {
  list-style: none;
}

.footer .socialLinks ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  /* width: 70%; */
}

.footer .socialLinks li {
  margin-left: 10px;
  margin-bottom: 10px;
}

.footer .socialLinks ul li a {
  transition: all 0.5s linear;
  color: #d8e6ed;
  border: 1px solid #d8e6ed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.footer .socialLinks ul li a svg {
  font-size: 1rem;
}

.footer .socialLinks li a:hover {
  background-color: #ba964f !important;
  color: #fff !important;
}


.copyright {
  margin-top: 30px;
  padding: 30px 0 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.footer .logo-footer img {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.main-header-front {
  width: 100%;
  max-width: 100%;
  height: 951px;
  background: url('../../assets/images/Bg.svg') no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-bottom: 600px;
}

.main-top-section {
  margin-bottom: 30px;
  padding: 30px 30px 0 30px;
  max-width: 100%;
  width: 100%;
}

.banner-layout {
  max-width: 1270px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.banner-layout img {
  width: 100%;
}

.bottom-section {
  margin: 80px 0;
  padding: 20px;
}

.bottom-section .left img {
  width: 100%;
}

.bottom-section .right {
  height: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 50px;
}

.bottom-section .right p {
  font-weight: 400;
}

.bottom-section .right a.tab {
  border: 2px solid #ddd;
  text-align: center;
  color: #161c2d;
  margin-top: 25px;
  color: #161c2d;
  width: 150px;
  border-radius: 50px;
  padding: 10px 20px;
  text-decoration: none;
  transition: 0.5 linear;
}

.bottom-section .right a.tab:hover {
  color: #fff !important;
  background-color: var(--yellow) !important;
}

.main-header-front .banner-content {
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 40px 20px;
  margin: 50px 0;
}

.main-header-front .banner-content h3 {
  color: #202942;
  font-size: var(--hero);
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  line-height: 1;
}

.main-header-front .banner-content p {
  font-weight: 600;
  margin: 20px 0;
  font-size: var(--des);
  margin: 0;
}



.main-header-front .banner-content .para-style {
  margin: 15px 0 0 0;
  font-size: var(--title);
}

.image_div_top {
  margin-top: 30px;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .image_div_top::after {
  position: absolute;
  content: '';
  width: 350px;
  height: 350px;
  background-color: var(--blue);
  top: 10%;
  right: -6%;
  border-radius: 100%;
  z-index: -1;
} */

.vedio-div {
  width: 100%;
  height: 300px;
  overflow: hidden;
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 10px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(5px); */
  /* -webkit-backdrop-filter: blur(5px); */
  margin-bottom: 30px;
}

.save-section {
  color: #00274e;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.save-section span {
  background: #afbcce;
  color: #00274e;
  margin-right: 10px;
  border-radius: 30px;
  padding: 5px 13px;
}

.tabs {
  text-align: center;
  padding-top: 25px;
}

.tabs ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tabs ul li a {
  color: #e0e8f0;
  text-align: center;
  background-color: #00274e;
  padding: 10px 30px;
  display: block;
  border-radius: 20px;
  font-size: var(--des);
  font-weight: 600;
  box-shadow: 0px 10px 30px #00285183;
  margin: 0 5px;
}

.tabs ul li a {
  text-decoration: none;
}

.tabs ul li a:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 10px 30px #c9922b;
}

.blue_btn {
  box-shadow: 0px 1px 15px #00274e88;
  border-radius: 26px;
  opacity: 1;
  background-color: #00264b;
  font-size: 15px;
  text-align: center;
  padding: 11px 45px;
  display: inline-block;
  margin-top: 25px;
  color: #ffff;
  text-decoration: none;
}

.blue_btn:hover {
  background-color: #c9922b;
  color: #fff !important;
}

.main-top-section .s-box {
  box-shadow: 0px 10px 30px #e1dfff66;
  padding: 10px 10px;
  border: 1px solid #fff;
  text-align: center;
}

.main-top-section .s-box:hover {
  box-shadow: 0px 10px 30px #e1dfff66;
  text-align: center;
  padding: 10px 10px;
  border: 1px solid #ddd;
  transition: all 0.5s ease-out;
  border-top: 1px solid #00264b;
}

.main-top-section .s-box h5 {
  padding: 10px 0;
  color: #161c2d;
  font-size: 1.2rem;
  font-weight: 500;
}

.main-top-section h6 {
  background-color: #e5e9ec;
  padding: 5px 15px;
  color: #00264b;
  display: inline-block;
  text-align: center;
  border-radius: 26px;
}

.main-top-section h3 {
  color: #00264b;
  font-size: 2rem;
  max-width: 550px;
  margin: 20px 0 30px 0;
}

.main-middle-section {
  max-width: 100%;
  width: 100%;
  padding: 0 0 30px 0;
  text-align: center;
  color: #fff;
}

.main-middle-section h3 {
  color: var(--blue);
  text-align: center;
  font-weight: 700;
  font-size: var(--heading);
  margin: 20px 0;
}

.main-middle-section h4 {
  font-weight: 600;
  text-align: center;
  font-size: var(--subhead);
  line-height: 30px;
  color: var(--blue);
}

.main-middle-section .t-box {
  margin-bottom: 25px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 20px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
}

.main-middle-section .t-box .icon {
  padding: 10px;
}

.main-middle-section .t-box .text {
  padding: 10px;
}

.main-middle-section .t-box .text h6 {
  font-size: 18px;
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
}

.main-middle-section .t-box .text p {
  color: #a8acb7;
  text-align: left;
  font-size: 0.9rem;
  line-height: 25px;
  height: 120px;
}

.main-bottom-section {
  width: 100%;
  padding: 20px;
  background: url('./../images/Bg2.png');
  background-size: cover;
  background-position: bottom;
}

.main-bottom-section h4 {
  font-size: var(--heading);
  font-weight: 700;
  text-align: center;
  color: var(--blue);
}

.main-bottom-section .headtext h4 {
  margin-top: 30px;
}

.main-bottom-section .headtext p {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  color: #000;
  line-height: 1.5;
  font-size: var(--subhead);
}

.main-bottom-section .message_text_image p {
  text-align: center;
  font-weight: 600;
  color: #000;
  line-height: 1.5;
  margin-bottom: 5px;
  font-size: var(--des);
}

.main-bottom-section .message_text_image h6 {
  text-align: center;
  font-weight: 700;
  color: var(--blue);
  line-height: 1.5;
  margin-bottom: 5px;
}

.main-before-footer {
  position: relative;
}

.main-before-footer .left-before-footer:before {
  background: #edf0f3;
  position: absolute;
  left: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  content: "";
}

.main-before-footer .right-before-footer {
  padding: 135px 50px;
}

.main-before-footer .container {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.main-before-footer .right-before-footer .right-form-cont {
  box-shadow: 0px 10px 60px #e8e8e8c4;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  min-height: 500px;
  padding: 23px;
  position: relative;
}

.main-before-footer .right-before-footer .right-form-cont:before {
  content: "";
  left: 20px;
  top: 20px;
  right: 20px;
  bottom: 20px;
  background-color: #fdf5e2;
  position: absolute;
  z-index: 0;
}

.main-before-footer .right-before-footer .right-form-cont h3 {
  position: relative;
  z-index: 1;
  background: url(../../assets/images/title-bg.jpg) no-repeat;
  color: #fff;
  font-size: 24px;
  padding: 15px;
  font-weight: 400;
}

.main-before-footer .right-before-footer .form {
  position: relative;
  z-index: 1;
  padding: 22px;
}

.main-before-footer .form input {
  border: none;
  width: 100%;
  padding-left: 15px;
  height: 40px;
}

.main-before-footer .right-before-footer legend {
  min-height: 85px;
  position: relative;
}

.main-before-footer .right-before-footer label {
  font-weight: normal;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 9px;
}

.main-before-footer .form ::placeholder {
  font-size: 15px;
  font-weight: normal;
  color: #7a7a7a;
}

.main-before-footer .right-before-footer label {
  display: block;
}

.main-before-footer .form .submit {
  border: none;
  background: #000002;
  color: #fff;
  font-size: 14px;
  padding: 15px 40px;
  margin-top: 10px;
}

.main-before-footer .left-before-footer {
  background: #edf0f3;
  position: relative;
  padding: 200px 0;
}

.main-before-footer .left-before-footer h6 {
  color: #000000;
  font-size: 14px;
  letter-spacing: 5.6px;
  margin: 0 0 30px 0;
}

.main-before-footer .left-before-footer h3 {
  letter-spacing: -1.26px;
  color: #000002;
  font-size: 42px;
  margin: 0 0 27px 0;
}

.main-before-footer .left-before-footer p {
  font-size: 17px;
  color: #7a7a7a;
  padding-right: 232px;
  line-height: 27px;
}

.main-before-footer .left-before-footer {
  padding-right: 120px;
}

.main-before-footer .info {
  margin-top: 40px;
}

.main-before-footer .info ul {
  list-style: none;
}

.main-before-footer .info li {
  display: inline-block;
}

.main-before-footer .blue {
  width: 45px;
  height: 45px;
  background-color: #002b58;
}

.main-before-footer .space {
  width: 20px;
  height: 50px;
}

.main-before-footer .number {
  padding: 0 3px;
  font-size: 32px;
  color: #000002;
}

.main-before-footer span {
  display: block;
  padding-top: 3px;
  color: #7a7a7a;
  font-size: 15px;
}

.main-form legend span {
  position: absolute;
  right: 12px;
  top: 50px;
  font-size: 20px;
  cursor: pointer;
  color: #002851;
}

.contact-map {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.error-page {
  max-width: 100%;
  width: 100%;
  padding: 100px 0;
  font-size: 30px;
  color: #161c2d;
  text-align: center;
}

.error-img {
  max-width: 700px;
  width: 100%;
  margin: 0 auto 100px;
}

.error-img img {
  width: 100%;
}

.error-page p {
  margin: 0 0 30px 0;
}

.error-page .tab {
  background: #00274e 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #00285179;
  border-radius: 28px;
  opacity: 1;
  width: 190px;
  height: 55px;
  color: #fff;
  font: normal normal bold 15px/130px Poppins;
  letter-spacing: 0px;
  display: block;
  margin: 0 auto;
  line-height: normal;
  text-decoration: none;
  padding-top: 15px;
}

.error-page h3 {
  text-align: center;
  font: normal normal normal 85px/85px Poppins;
  letter-spacing: -2.55px;
  margin: 0 0 20px 0;
  color: #161c2d;
  opacity: 1;
}

.steps {
  position: relative;
  padding: 40px 0;
}

.steps .step1,
.steps .step2 {
  position: relative;
}

.steps .step1::before {
  content: " ";
  position: absolute;
  top: 42%;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 1;
  background-color: #002954;
}

.steps .step2::before {
  content: " ";
  position: absolute;
  top: 42%;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 1;
  background-color: #d8d8d8;
}

.steps .step {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.steps .step img {
  width: 100%;
}

.steps h5 {
  text-align: center;
  text-align: center;
  font: normal normal normal 30px/50px Poppins;
  letter-spacing: -0.9px;
  color: #161c2d;
}

/*--------Registration Form -----------*/

.registration-form h4 {
  letter-spacing: 0px;
  color: #011c40;
  font-size: 24px;
  font-weight: 600;
}

.registration-form .container {
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  padding: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #0000000a;
  border-radius: 5px;
  overflow: hidden;
}

.registration-form .form-check-input:checked {
  background-color: #b4975a;
  border-color: #b4975a;
}

.registration-form .formComponent {
  position: relative;
  font-size: 15px;
  color: #202020;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  min-height: 90px;
  max-width: 270px;
}

.registration-form .custom-field {
  border: 1px solid #c2c2c2;
  height: 50px;
  border-radius: 15px;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #202020;
}

.registration-form .formComponent label {
  text-align: left;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 1px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.registration-form .customFileUpload {
  max-width: 270px;
  margin: 0 auto;
}

.registration-form .form-head {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #0000000a;
  opacity: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 10px;
  padding: 30px;
}

/* #uploadImage {
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  margin-bottom: 0px;
  opacity: 0;
}
.uploadPreview {
  max-width: 100%;
  height: 300px;
  border-radius: 10px;
  position: relative;
  display: block;
  background-color: #d8d8d8;
  overflow: hidden;
}
.uploadPreview img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  margin: auto;
} */
/* .upload-button {
  box-shadow: 0px 4px 4px #0000000a;
  border: 1px solid #011c40;
  height: 50px;
  border-radius: 1px;
  text-transform: uppercase;
  color: #011c40;
  font-weight: 600;
  font-size: 16px;
  display: block;
  border-radius: 10px;
  text-align: center;
  padding-top: 12px;
}
.upload-button i {
  position: absolute;
  left: 26px;
  top: 15px;
}
.file-filed-cont {
  position: relative;
  margin-top: 20px;
} */
.registration-form .form-check label {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202020;
  padding-top: 5px;
}

.registration-form hr {
  margin: 0 0 39px;
  position: relative;
  margin-left: -47px;
  width: auto;
  margin-right: -47px;
}

.registration-form .form-check-input:checked {
  background-color: #b4975a;
  border-color: #b4975a;
}

.registration-form .form-foot {
  margin-bottom: 65px;
  padding: 30px;
}

.registration-form .form-foot h4 {
  text-align: left;
  font: normal normal bold 24px/35px Poppins;
  letter-spacing: 0px;
  color: #011c40;
  margin: 0 0 25px 0;
  opacity: 1;
}

.registration-form .tab-p {
  background: #011c40 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #0000000a;
  opacity: 1;
  text-align: left;
  font: normal normal bold 18px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  height: 55px;
  padding: 0 45px;
  border-radius: 15px;
  margin-right: 15px;
}

.registration-form .tabs-cont {
  padding-top: 40px;
}

.registration-form .eye-password {
  position: absolute;
  right: 9px;
  top: 40px;
  font-size: 18px;
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/*--------Registration Form -----------*/

/*--------Checkout -----------*/
.checkout-page {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
}

.info-box {
  border: 1px solid #000000;
  height: 100%;
}

.account-info .col-xl-6:last-child .info-box {
  border-left: none;
}

.info-box h3 {
  text-align: center;
  color: #fff;
  background: #002851;
  font-size: 25px;
  padding: 13px;
}

.account-info {
  max-width: 100%;
  width: 100%;
  padding: 30px 0;
}

.info-box>div {
  padding: 25px;
}

.info-box>div select {
  display: block;
}

.info-box legend {
  display: block;
  float: none;
}

.card-number {
  position: relative;
  padding-right: 78px;
}

.card-number img {
  position: absolute;
  right: -7px;
  top: 31px;
  width: 76px;
}

.cvv {
  max-width: 140px;
  width: 100%;
}

.container-plan {
  max-width: 280px;
  width: 100%;
}

.container-plan select {
  width: 100%;
  height: 55px !important;
  border-radius: 12px;
  border: 1px solid #aaa;
  font-size: 20px;
  font-weight: 600;
  color: #202020;
  padding-left: 15px;
  margin-bottom: 10px;
  margin-top: 6px;
}

.info-box strong {
  font-size: 20px;
  color: #000;
  line-height: 35px;
  font-weight: 700;
}

.select-custom {
  appearance: none;
  background: url(../../assets/images/arrow.svg) no-repeat 97% 52%;
}

.container-plan legend {
  min-height: 220px;
}

.container-plan select:focus-visible,
.container-plan select:focus {
  border: 1px solid #999;
  outline: none;
}

.container-plan label {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 6px 0;
}

.info-box h4 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  margin: 0 0 16px 0;
}

.info-box label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.info-box legend input {
  border: 1px solid #a5a5a5;
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding-left: 10px;
}

.info-box legend select {
  border: 1px solid #a5a5a5;
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding-left: 10px;
}

.info-box .form-cols {
  padding-right: 20px;
  padding-bottom: 30px;
}

.resp-table {
  padding: 10px 15px !important;
}

.resp-table table {
  width: 100%;
}

.resp-table thead td {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.resp-table tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #202020;
}

.info-box tfoot tr:last-child td {
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.info-box footer {
  border-top: 1px dashed #000;
  padding-top: 20px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 15px;
}

.info-box footer ul {
  margin: 0 0 15px 20px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}

.info-box tfoot td {
  padding-top: 15px;
  font-size: 16px;
  font-weight: 700;
}

.info-box tfoot td {
  font-weight: 700;
  color: #000;
}

.info-box footer h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.text-danger {
  font-size: 0.9rem;
}

/*--------Checkout -----------*/
@media (min-width: 1600px) {
  .main-before-footer .right-before-footer .right-form-cont {
    margin-right: -50px;
  }
}

@media (max-width: 1600px) {
  .main-header-front {
    background: url('../../assets/images/Bg.svg') no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
  }

  /* .vedio-div {
    height: 150px !important;
  } */

  /* .image_div_top::after {
    position: absolute;
    content: '';
    width: 250px;
    height: 250px;
    background-color: var(--blue);
    top: 35%;
    right: -6%;
    border-radius: 100%;
    z-index: -1;
  } */

  .main-header-front .banner-content h3 {
    line-height: 60px;
  }

  .header-tabs:before {
    position: absolute;
    left: 0px;
  }

  .navigation .nav-item {
    padding: 0 15px;
  }
}

@media (max-width: 1200px) {
  .footerColumn {
    margin-bottom: 35px;
    padding-bottom: 15px;
  }

  .headersection .logo {
    width: 100%;
    max-width: 170px;
    margin: 0 auto;
  }

  .header-tabs:before {
    display: none;
  }

  .header-tabs {
    position: absolute;
    right: 25px;
    top: 9px;
  }

  .navbar-toggler .fa-solid {
    font-size: 40px;
  }

  .navigation {
    top: -56px;
    position: relative;
  }

  .navigation .nav-item {
    padding: 8px 0px;
    border-bottom: 1px dotted #ccc;
  }

  .headersection .logo {
    top: 4px;
    position: relative;
  }

  .navigation .nav-item.active:before {
    right: auto;
    width: 30px;
    left: 1px;
    top: 0;
  }

  div#mainNav {
    padding-top: 20px;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    border: 2px solid var(--blue) !important;
    box-shadow: none !important;
  }

  .header-tabs .firstTab {
    padding: 8px 20px;
    margin-right: 10px;
  }

  .header-tabs .lastTab {
    padding: 7px 20px;
  }

  .main-top-section .top-left {
    max-width: 800px;
    color: #6e727d;
    line-height: 29px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .main-top-section h3 {
    color: #00264b;
    font-size: 2rem;
    padding: 0 20px;
    margin: 20px auto;
  }

  .bottom-section .right {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    color: #6e727d;
    line-height: 29px;
    padding-top: 50px;
    padding-bottom: 40px;
    text-align: center;
  }

  .headersection {
    height: 80px;
  }

  .navigation {
    top: 78px;
    position: absolute;
    z-index: 4;
    left: 0px;
    background: #ffff;
    right: 0;
    padding-left: 10px;
  }

  .navigation .navbar-toggler {
    top: -30px;
    left: -10px;
  }

  .navigation .navbar-toggler {
    top: -57px;
    left: 9px;
    position: absolute;
  }

  .main-bottom-left {
    max-width: 670px;
    width: 100%;
    float: none;
    font-size: 17px;
    color: #6e727d;
    margin: 0 auto 60px;
    padding-top: 50px;
  }

  .main-bottom-left .tab {
    margin-top: 20px;
    text-align: center;
  }

  .main-bottom-left {
    text-align: center;
    padding-right: 0;
  }



  .left-before-footer {
    padding: 100px 30px !important;
    text-align: center;
    padding-right: 0;
  }

  .main-before-footer .left-before-footer p {
    padding-right: 0;
  }

  .main-before-footer .right-before-footer .right-form-cont {
    max-width: 800px;
  }

  .main-before-footer .right-before-footer .right-form-cont h3 {
    background-size: cover;
  }


}

@media (max-width: 767px) {
  .banner-layout {
    bottom: auto;
    top: 400px;
  }

  .main-top-section {
    padding-top: 80px;
    max-width: 100%;
    width: 100%;
  }

  .banner-layout {
    bottom: auto;
    top: 390px;
    margin-top: 30px;
    max-width: 400px;
  }

  .main-header-front .banner-content h3 {
    font-size: 2.5rem;
    margin: 0;
    line-height: normal;
  }

  .main-header-front {
    height: auto;
  }

  .header-tabs a {
    font-size: 11px;
  }

  .header-tabs .firstTab {
    padding: 3px 15px;
    margin-right: 5px;
    height: 35px;
  }

  .header-tabs .lastTab {
    padding: 3px 15px;
    height: 35px;
  }

  .header-tabs {
    position: absolute;
    right: 14px;
    top: 9px;
  }

  .navigation .navbar-toggler {
    top: -58px;
    left: 7px;
    position: absolute;
  }

  .navigation {
    left: 0;
    padding-left: 0;
  }

  .headersection .logo {
    width: 100%;
    max-width: 173px;
    margin: 0;
    padding-top: 5px;
    padding-left: 39px;
  }

  .main-bottom-left {
    text-align: center;
    padding-right: 0;
    padding: 0 20px;
  }

  .main-bottom-left h3 {
    font-size: 27px;
  }

  .main-before-footer .left-before-footer h3 {
    font-size: 32px;
  }

  .main-before-footer .right-before-footer {
    padding: 65px 14px;
  }

  .main-middle-section h4 {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    padding: 0 20px;
  }

  .main-middle-section .t-box {
    border: 1px solid #707070;
    padding: 27px 15px 30px 10px;
  }

  .main-middle-section .t-box .icon {
    padding: 0 15px 0 0;
  }

  .header-inner h1 {
    color: #161c2d;
    text-align: center;
    /* font-size: 38px; */
  }

  .header-inner {
    background-color: var(--lightsky);
    padding: 55px 0;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pricing-plan blockquote {
    text-align: center;
    color: #00274e;
    font-size: 25px;
  }

  .pricing-plan .plan-box .price big {
    font-size: 28px;
  }

  .pricing-plan .plan-box .price big:before {
    content: "$";
    font-size: 17px;
    color: #e4ab3e;
    position: absolute;
    left: -12px;
    top: 3px;
  }

  .pricing-plan {
    width: 100%;
    max-width: 100%;
    padding: 60px 0 40px;
  }

  .newsletter-cont {
    max-width: 100%;
    width: 100%;
    background: url(../../assets/images/left-bg.png) no-repeat -200px -100px,
      url(../../assets/images/right-bg.png) no-repeat no-repeat 310px 118px,
      #f4f7fa;
    padding: 60px 0;
    height: auto;
  }

  .newsletter-cont h3 {
    font-size: 22px;
  }

  .newsletter-cont .submit {
    background-color: #002b58;
    color: #fff;
    text-align: center;
    width: 110px;
    top: 16px;
    right: 16px;
    position: absolute;
    height: 48px;
    font-size: 12px;
    border-radius: 40px;
    font-weight: 600;
  }

  .newsletter-cont input {
    width: 100%;
    height: 77px;
  }

  .form-newsletter {
    margin-bottom: 0px;
  }

  .footer {
    padding: 70px 0 40px;
    display: flex;
    flex-direction: column;
  }


  .footer .logo-footer img {
    width: 100%;
    max-width: 170px;
    height: auto;
  }

  .contact-page h3 {
    color: #161c2d;
    font-size: 27px;
  }

  .error-page p {
    margin: 0 0 30px 0;
    font-size: 20px;
  }

  .error-page h3 {
    font-size: 70px;
  }

  .header-inner h4 {
    font-size: 22px;
    line-height: normal;
  }

  .steps .step {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .steps .step1::before {
    height: 5px;
  }

  .steps .step2::before {
    height: 5px;
  }

  .steps .step2::before,
  .steps .step1::before {
    top: 32%;
  }

  .steps h5 {
    font-size: 22px;
  }

  .registration-form .formComponent {
    max-width: 100%;
  }

  .registration-form .tab-p {
    opacity: 1;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 15px;
    margin-right: 2px;
  }

  .registration-form .tabs-cont {
    padding-top: 0px;
  }

  .registration-form .form-head {
    padding: 10px 10px 30px 10px;
  }

  .registration-form .form-foot {
    padding: 10px;
  }

  .navbar-toggler .fa-solid {
    font-size: 34px;
  }
}

/* code Optimization */
#responsive-navbar-nav {
  justify-content: flex-end;
}

.navbar-brand img {
  width: 180px;
}

.navbar-light .navbar-nav.menu_nav .nav-link {
  color: var(--blue) !important;
  padding: 1rem 1.5rem !important;
  font-size: var(--des) !important;
  font-weight: 400;
  height: 100%;
  /* margin: 0 5px; */
  transition: 0.2s;
  position: relative;
}

/* 
.navbar-light .navbar-nav.menu_nav .nav-link:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2px;
  height: 30%;
  background-color: var(--blue);
  transition: 0.5s;
} */

.sub_footer a:hover {
  color: #ba964f;
}

.navbar-light .navbar-nav.menu_nav .nav-link:hover:after {
  background-color: var(--yellow);
}



.navbar-light .navbar-nav.menu_nav li:hover .nav-link {
  color: var(--yellow) !important;
}

.navbar-light .navbar-nav.menu_nav .nav-link.active:after {
  background-color: var(--yellow);
}

.navbar-light .navbar-nav.menu_nav .nav-link.active {
  color: var(--yellow) !important;
}

.navbar-light .navbar-nav.right-nav .nav-link.linktext {
  border-radius: 30px;
  font-size: var(--des);
  font-weight: 600;
  padding: 8px 40px;
  text-align: center;
  color: #fff !important;
  background-color: var(--blue);
  box-shadow: 0 1px 10px #00285157;
  margin: 0 20px;
}

.navbar-light .navbar-nav.right-nav .nav-link.linktext:hover {
  background-color: #c9922b;
  color: #fff;
  box-shadow: 0px 1px 20px #c9922b;
}

a.nav-link.btnPrimary {
  border-radius: 30px;
  font-size: var(--des) !important;
  font-weight: 400;
  padding: 8px 25px !important;
  text-align: center;
  color: #fff !important;
  background-color: #c9922b;
  box-shadow: 0 1px 10px #d38f0040;
  transition: 0.2s linear;
}

a.nav-link.btnPrimary:hover {
  background-color: var(--blue);
  color: #fff;
  box-shadow: 0 1px 30px #00285157;
}

a.softNameLink {
  border-bottom: 1px solid #fff;
}

.link-outline-primary {
  padding: 7px 15px !important;
  border: 1px solid var(--blue) !important;
  margin-right: 10px;
  border-radius: 50px;
  font-size: 0.9rem !important;
  font-weight: 400;
  color: var(--blue) !important;
  text-align: center;
}

.link-outline-primary:hover,
.linkprimary:hover {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
  color: #fff !important;
}

.linkprimary {
  padding: 8px 15px !important;
  background: var(--blue) !important;
  margin-right: 10px;
  border-radius: 50px;
  font-size: 0.9rem !important;
  font-weight: 400;
  color: #fff !important;
  text-align: center;
}

.navbar-light .navbar-nav.right-nav.mobile .nav-link {
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px !important;
  text-align: center;
  color: #fff !important;
  transition: all 0.5s linear;
}

ul.footerLinks-copy {
  list-style: none;
  height: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
}

ul.footerLinks-copy li {
  border-right: 2px solid #fff;
  padding-right: 10px !important;
  margin-right: 10px;
  color: #fff;
  line-height: 14px;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
}

ul.footerLinks-copy li a {
  color: #fff;
  padding: 0 0 0 2px;
  /* text-decoration: underline; */

}

ul.footerLinks-copy li a:hover {
  color: var(--gold) !important;
}

ul.footerLinks-copy li:last-child {
  border-right: 0px;
  padding-right: 0;
  margin-right: 0;
}

.mobile {
  display: none !important;
}

.brand {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

/* @media (min-width: 480px) and (max-width: 992px) {
  .navbar-light .navbar-nav.mx-auto .nav-link:after {
    content: none;
  }
  .brand .mobile {
    display: flex !important;
    justify-content: center;
    flex-direction: row !important;
    border: 1px solid;
  }

  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
} */

@media (max-width: 380px) {
  .navbar-brand {
    width: 63%;
  }

  /* pricing */
  .pricing-plan h5 {
    font-size: 1rem;
  }

  .newsletter-cont h3 {
    font-size: 0.8rem;
    width: 80%;
  }

  .newsletter-cont h3~p {
    width: 80%;
    margin: 0 auto;
  }

  .newsletter-cont input::placeholder {
    font-size: 0.7rem;
  }
}

/* auto_renewal */

/* register Pages */
.register_section {
  width: 100%;
  height: 100vh;
}

.register_section .register_section_overlay {
  width: 100%;
  height: 100%;
}

.register_logo {
  width: 200px;
  margin: 0 auto 20px auto;
}

.terms_p {
  font-weight: 400;
  text-align: center;
  font-size: 0.9rem !important;
}

.terms_p a {
  font-weight: 600;
  color: var(--gold);
  font-size: 0.9rem;
  text-decoration: underline;
}

.register_section .form-check {
  display: flex;
  justify-content: center;
}

.register_section .form-check input {
  margin-right: 10px;
}

.terms_p a:hover {
  color: var(--blue);
}

.register_title {
  letter-spacing: 0px;
  color: var(--blue);
  font-weight: 700;
  font-size: 2.2rem;
  margin-bottom: 10px;
}

.register_des {
  font-size: 1.1rem;
  text-align: center;
  font-size: 500;
  /* color: var(--blue); */
  margin-bottom: 30px;
}

.register_section .form-control {
  border-radius: 8px !important;
  background-color: var(--lightsky);
  color: var(--blue);
  font-size: 0.9rem;
  padding: 10px 20px;
  border: none;
}

.form-control {
  border-radius: 8px !important;
  background-color: var(--lightsky) !important;
  color: var(--blue);
  font-size: 0.9rem;
  padding: 10px 20px !important;
  border: none !important;
}

.main-form2 .form-select {
  border-radius: 10px !important;
  background-color: var(--lightsky) !important;
  color: var(--blue);
  font-size: 0.9rem;
  padding: 10px 20px;
  border: none;
}

.form-select {
  border-radius: 10px !important;
  background-color: var(--lightsky) !important;
  color: var(--blue);
  font-size: 0.9rem;
  padding: 10px 20px !important;
  border: none !important;
}

.main-form2 .form-select:focus,
.form-select:focus {
  box-shadow: none !important;
}

.register_section .form-control:is(:focus, :hover),
.form-control:is(:focus, :hover) {
  background-color: var(--lightsky) !important;
  box-shadow: none !important;
}

.register_section .form-control::placeholder {
  color: var(--blue) !important;
  font-size: 0.8rem;
}

.register_section .input-group-text {
  color: var(--blue);
  background-color: #fff !important;
}

.register_section .register_section_overlay .bg_image {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

/* @media (max-width: 992px) {
  .register_section .register_section_overlay img {
    height: auto !important;
  }
} */

.sub_footer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.sub_footer img {
  width: 200px;
}

.sub_footer p {
  padding-top: 20px;
  color: #fff;
}

#canvas-container {
  height: 10px;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  #canvas-container {
    height: 10px;
    width: 100%;
    position: relative;
  }
}

/* Password Validation */
.validation_heading {
  color: var(--blue);
  font-size: 1rem;
  font-weight: 600;
  margin: 10px 0;
}

.validation_list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 10px 0;
}

.validation_list li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.validation_list li h5 {
  color: var(--yellow);
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 600;

}

.validation_list li span {
  color: var(--yellow);
  font-size: 0.8rem;
  font-weight: 600;

}

.validation_list li.active h5 {
  color: var(--blue);
}

.validation_list li.active span {
  color: var(--blue);
}

.form-label {
  text-transform: capitalize;
  color: var(--blue);
  font-weight: 700;
}

.password-input-container {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
}

.animateZoom {
  animation: upDown 1s ease-in-out infinite alternate;
  transition: 0.4s ease-in-out;
}

@keyframes upDown {
  from {
    transform: scale(1);
  }

  to {
    background-color: var(--yellow);
    color: #fff;
    transform: scale(1.1);
  }
}

.password {
  position: relative;
}

.show_password {
  position: absolute;
  cursor: pointer;
  top: 55%;
  font-size: 1.2rem !important;
  right: 10px;
  color: var(--blue);
}

.show_password2 {
  position: absolute;
  cursor: pointer;
  top: 60%;
  font-size: 1.2rem !important;
  right: 10px;
  color: var(--blue);
}

.table tbody tr:nth-child(2n + 1) {
  background-color: #ededed;
}

.table tbody tr {
  background-color: transparent;
}

.level-0 {
  position: relative;
}

.level-1 {
  width: 100%;
  margin: 0 20px;
  color: black;
  text-align: left;
  position: absolute;
  left: 0;
  top: 45px;
  display: none;
}

.level-0 td {
  vertical-align: top;
}

.level-1 td label {
  font-weight: bold;
}

body table .level-1 td span {
  margin-left: 10px;
  display: initial !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.tr-expand {
  height: 100px;
}

.m-0 {
  margin: 0 !important;
}

.level-1 table td {
  line-height: 2;
  padding: 0 10px;
}

.page_not_found {
  padding: 50px 10px;
}

.page_not_found a {
  box-shadow: 0px 1px 15px #00274e88;
  border-radius: 26px;
  opacity: 1;
  background-color: #00264b;
  font-size: 15px;
  text-align: center;
  padding: 11px 45px;
  display: inline-block;
  margin-top: 25px;
  color: #ffff;
  text-decoration: none;
}

.page_not_found a:hover {
  background-color: #c9922b;
  color: #fff !important;
}

.summary {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin: 100px 0;
  background: transparent linear-gradient(76deg, #CCE7FC 0%, #FFF3D9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #2C82ED66;
}

.summary .summary_item {
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.summary .summary_item h4 {
  font-weight: 700;
  font-size: 2.2rem;
  color: var(--blue);
}

.summary .summary_item h5 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 10px;
}

.whychoseus h4 {
  color: var(--blue);
  text-align: center;
  font-weight: 700;
  font-size: var(--heading);
  margin: 20px 0;
}

.whychoseus p {
  font-weight: 600;
  text-align: center;
  font-size: var(--subhead);
  line-height: 30px;
}

/* .chose_sec:hover .chose_item {
  transform: scale(0.9);
} */

.chose_item {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 10px 30px #E1DFFF66;
  position: relative;
  transition: 0.5s linear;
}

.chose_item:hover {
  cursor: pointer;

}

.chose_item:hover img {
  transform: scale(1.2);
}

.chose_item img {
  transition: 0.5s linear;
  width: 100%;
  height: 160px;
}

.chose_item h4 {
  color: var(--blue);
  text-align: center;
  font-weight: 600;
  font-size: var(--title);
  margin: 20px 0;
  height: 55px;
}

/* service Item */

.service_item,
.service_item2 {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0px 1px 10px 0px #0000001A;
  position: relative;
  transition: 0.5s linear;
  /* margin-bottom: 20px; */
  margin: 20px 5px;
}

.service_item:hover,
.service_item2:hover {
  cursor: pointer;
}

.service_item:hover img,
.service_item2:hover img {
  transform: scale(1.2);
}

.service_item img,
.service_item2 img {
  transition: 0.5s linear;
  width: 30%;
}

.service_item h4,
.service_item2 h4 {
  color: var(--blue);
  font-weight: 600;
  font-size: var(--title);
  margin-top: 10px;
  line-height: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_item h6 {
  color: var(--blue);
  font-size: var(--des);
  text-align: left;
  min-height: 60px;

}

.service_item2 h6 {
  color: var(--blue);
  font-size: 1rem;
  text-align: left;
  min-height: 90px;
}

@media (width:1024px) and (height:768px) {
  .service_item2 h6 {
    min-height: 135px;
  }
}

/* Meassage */
.message_text_image {
  margin-top: 10px;
}

/* blogs Caed Design */
.blogs_section {
  padding: 30px;
  min-height: 500px;

}

.blogs_section h3 {
  font-weight: 600;
  color: var(--blue);
  font-size: var(--heading);
}

.blogs_section .title_blogs {
  font-weight: 600;
  color: var(--blue);
  margin-top: 10px;
  font-size: var(--title);
}

.blogs_section .title_blogs:hover {
  color: var(--yellow);
  cursor: pointer;
}

.blog-card {
  background-color: var(--blue) !important;
  margin-bottom: 20px;
  border-radius: 20px !important;
  overflow: hidden;
  cursor: pointer;
  height: 100% !important;
}

.blog-card img {
  transition: 0.3s linear;
}

.blog-card:hover img {
  transform: scale(1.03);
}



.blog-card .card-title {
  color: #fff;
  font-size: var(--des);
  margin: 10px 0;
}

.blog-card .card-body {
  padding: 20px;
}

.blog-card .card-img-top {
  border-radius: 0 0 20px 20px;
}

.blog-card:hover {
  transition: 0.3s linear;
}

.blog-card h5 {
  color: #fff;
  margin-bottom: 0px;
  /* min-height: 50px; */
  overflow: hidden;
  font-weight: 600;
  font-size: var(--title);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-card:hover h5 {
  color: var(--yellow);
}

/* Blgs Page */
.top_blog_section {
  padding: 30px;
}

.top_blog_section:hover img {
  transform: scale(1.03);
}

/* Video blog content style */
body .video-blogs .blog-card {
  border-radius: 0 !important;
  background: transparent !important;
  color: black !important;
  border: 0;
}

body .video-blogs .blog-card h5 {
  color: #161c2d !important;
  font-size: 16px;
}

body .video-blogs .blog-card .card-body {
  border: 0 !important;
  padding: 0;
}

body .video-blogs .blog-card .card-img-top {
  border-radius: 10px;
}

.video-blogs {}

.video-blogs {}

.download-button:hover {
  color: white;
  background-color: #ba964f;
  border: 0;
}

.download-button:focus {
  border: 0 !important;
  outline: 0 !important;
}

.download-button svg:hover {
  color: white !important;
}

.download-button {
  background-color: var(--blue);

  font-size: var(--des);
  border: 0;
  border-radius: 100px;
  color: white;
  padding: 0px 20px;
  width: 160px;
  font-weight: 600;
}

.download-button svg {
  padding-left: 10px;
}

.downloadItem h4 {
  font-size: 12px;
}

.downloadItem h5 {}

.top_blog_section img {
  cursor: pointer;
  transition: 0.3s linear;
}

.blog_top_text {
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.auther_list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 10px;
  padding: 0 !important;
}


.auther_list li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: var(--text);
}

.auther_list li p {
  margin-bottom: 0;
  color: var(--blue);
  font-size: var(--des);
  padding: 0 !important;

}

.blog_top_text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: var(--des);
}

.auther_list li svg {
  margin-right: 10px;
  color: var(--blue);
  font-size: 20px;
}

.btn.btn-default {
  padding: 10px 20px;
  border-radius: 100px;
  background-color: var(--lightsky);
  font-size: var(--des);
  font-weight: 500;
  color: var(--blue);
  margin-top: 20px;
}

.btn.btn-default:hover {
  background-color: #c9922b;
  color: #fff;
}

.btn.btn-default2 {
  padding: 10px 20px;
  border-radius: 100px;
  border: 2px solid var(--blue);
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--blue);
  float: right;
}

.btn.btn-default2:hover {
  background-color: var(--blue);
  color: #fff;
}


.powerdby {
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  display: block;
  margin-left: 5px;
}

.blog-detail {
  padding: 20px;
}

.blog-detail h5 {
  font-weight: 600;
  color: var(--blue);
  margin-bottom: 30px;
  padding-left: 15px !important;
}

.blog-detail h4 {
  font-weight: 600;
  color: var(--blue);
  margin: 20px 0;
  padding-left: 15px !important;
}

.blog-detail ul,
.blog-detail ul {
  padding-left: 20px !important;
}

.back {
  color: #fff;
  background-color: var(--blue);
  font-size: 30px;
  cursor: pointer;
  padding: 5px;
  border-radius: 30px;
}

.back:hover {
  background-color: #c9922b;
  color: #fff;
}

/* Vedio */
/* .video-react-video{
  width: 100%;
}
.video-react-controls-enabled{
  padding: 0 !important;

  display: flex;
  flex-direction: column;
}
.video-react-control-bar{
  width: 100%;
  border: 1px solid blue;
  display: flex;
} */

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  /* border-radius: 30px; */
  background-color: var(--blue);
  transition: all 0.2s linear;
}

.video-react .video-react-big-play-button.video-react-big-play-button-center:hover {
  background-color: #c9922b;
  color: #fff;
  /* box-shadow: 0px 10px 30px #c9922b; */
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  overflow: hidden;
}

.play svg:hover {
  color: #c9922b !important;
}

.react-player {
  width: 100% !important;
  height: 480 !important;
}

.free-download-form {}

.free-download-form .left-area {}

.free-download-form .left-area img {
  width: 100%;
}

.free-download-form .left-area p {
  font-size: var(--des);
  font-weight: 500;
  color: var(--blue);
  line-height: 1.3;
}

.free-download-form .left-area ul {
  list-style: none;
}

.free-download-form .left-area ul li {
  font-size: var(--des);
  font-weight: 500;
  color: var(--blue);
  line-height: 2.2;
}

.free-download-form .left-area ul li .icon {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0px;
}

.free-download-form .left-area ul li svg {
  color: #00bd00;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}

/* Customize the label (the cst-checkbox) */
.cst-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px !important;
}

/* Hide the browser's default checkbox */
.cst-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 4px;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.cst-checkbox:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cst-checkbox input:checked~.checkmark {
  background-color: var(--blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cst-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cst-checkbox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.free-download-form .right-area img {
  width: 200px;
  margin-bottom: 10px;
}

.free-download-form .right-area {
  text-align: center;
}

.free-download-form .right-area .top-right h3 {
  text-align: center !important;
  font-size: var(--heading);
  color: var(--blue);
  font-weight: 600;
}

.free-download-form .right-area .top-right p {
  text-align: center !important;
  font-size: var(--des);
  color: var(--blue);
  font-weight: 500;
}

.free-download-form .right-area form label span {
  color: red;
}

.free-download-form .right-area form .text-danger {
  /* margin-bottom: 40px; */
  width: 100%;
  display: block;
  text-align: left;
}

.free-download-form .right-area form label {
  font-weight: 600;
  color: var(--blue);
  float: left;
  display: inline-block;
  margin-bottom: 6px;
}

.free-download-form .right-area form .submit {
  border-radius: 6px;
  box-shadow: none !important;
  width: 100%;
}

.free-download-form .right-area form .checkbox-label {
  width: 100%;
  display: block;
  text-align: center;
  height: 20px;
  max-width: 300px;
  margin: auto;
  margin-bottom: 30px;
}

.free-download-form .right-area form input[type="checkbox"] {
  display: inline-block;
  float: left;
  width: 20px;
  margin: 2px 8px;
  height: 20px;
  cursor: pointer;
}

.free-download-form .right-area form p {
  font-size: 12px;
  color: var(--blue);
  width: 100%;
  max-width: 560px;
  margin: auto;
  text-align: center;
  font-weight: 500;
  line-height: 1.3;
}

.free-download-form .right-area .privacy-policy-btn:hover {
  background-color: #c9922b;
  color: #ffffff;
  transition: .2s;
  cursor: pointer;
}


.free-download-form .right-area .privacy-policy-btn.mt-15 {
  margin-top: 15px !important;
}

.free-download-form .right-area .privacy-policy-btn {
  background: var(--lightsky);
  border-radius: 6px;
  padding: 10px 1px;
  text-align: center;
  color: #000000;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: var(--des);
  transition: 0.3s linear;
  width: 100%;
  display: block;
  margin-top: 50px;
}

.logoText {
  color: #fff;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.subText {
  color: var(--blue) !important;
  font-weight: 400 !important;
  font-size: 1.25rem !important;
}